import { render, staticRenderFns } from "./homeCenter.vue?vue&type=template&id=971a9ba4&scoped=true"
import script from "./homeCenter.vue?vue&type=script&lang=js"
export * from "./homeCenter.vue?vue&type=script&lang=js"
import style0 from "./homeCenter.vue?vue&type=style&index=0&id=971a9ba4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "971a9ba4",
  null
  
)

component.options.__file = "homeCenter.vue"
export default component.exports