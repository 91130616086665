import { render, staticRenderFns } from "./yunweiHome.vue?vue&type=template&id=29197f07&scoped=true"
import script from "./yunweiHome.vue?vue&type=script&lang=js"
export * from "./yunweiHome.vue?vue&type=script&lang=js"
import style0 from "./yunweiHome.vue?vue&type=style&index=0&id=29197f07&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29197f07",
  null
  
)

component.options.__file = "yunweiHome.vue"
export default component.exports