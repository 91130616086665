// 发电量统计
export default {
  setElectrcityStatisticsOptions(edata){
    const efficiencyData = edata.map(item=>{
      return item.efficiency
    })
    const powerData = edata.map(item=>{
      return item.powerNum
    })
    return {
      grid: {
        left: "6%",
        right: "6%",
        bottom: "4%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: [
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
            "星期日",
          ],
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#97b3cb", // x轴颜色
            },
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
              width: 1,
            },
          },
        },
        {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitArea: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          data: [
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
            "星期日",
          ],
        },
      ],
      yAxis: [
        {
          name: "功率(kW)",
          nameTextStyle: {
            fontSize: 12,
            color: "rgba(255, 255, 255, 1)",
            align: "right",
          },
          type: "value",
          axisLabel: {
            formatter: "{value}",
            show: true,
            textStyle: {
              color: "#97b3cb", // x轴颜色
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed", // 线型为虚线
              color: "#97b3cb",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#fff",
              width: 1,
            },
          },
        },
        {
          name: "发电总量：千瓦",
          nameTextStyle: {
            fontSize: 12,
            color: "rgba(255, 255, 255, 1)",
            align: "right",
          },
          type: "value",
          axisLabel: {
            show: true,
            formatter: "{value}",
            textStyle: {
              color: "#97b3cb", // x轴颜色
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed", // 线型为虚线
              color: "#97b3cb",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
              width: 1,
            },
          },
        },
      ],
      series: [
        {
          name: "功率(kW)",
          type: "bar",
          barWidth: 12,
          data: efficiencyData,//[370, 700, 200, 400, 300, 300],
          itemStyle: {
            color: "#45f9d6",
          },
          label: {
            show: false, // 开启标签显示
            position: "top", // 标签的位置，可以根据需要调整
            textStyle: {
              color: "#45f9d6",
            },
          },
        },
        {
          type: "line",
          name: "发电总量：千瓦",
          data: powerData,//["93", "50", "33", "99", "62", "6", "45"],
          zlevel: 3,
          color: "rgba(250, 204, 20, 1)",
        }
      ],
    }
  }
}
