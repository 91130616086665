<template>
    <div class="head-class-left">
        <div class="center-item-head">
            <div class="item-head-class">
                <div>
                    <div class="title-class">功率辐射曲线</div>
                    <img src="../../../assets/images/yunwei/home-background.svg" class="background-img" alt="">
                </div>
                <div class="form-item-class">
                    <div style="width: 40px">日期</div>
                    <el-date-picker
                    v-model="date"
                    type="date"
                    @change="getData"
                    placeholder="选择日期">
                    </el-date-picker>
                </div>
            </div>
        </div>
        <div class="electrcity-chart-class">
            <div ref="faultPowerChart" id="faultPowerChart" class="fourth-left"></div>
        </div>
    </div>
</template>

<script>
import setOption from "../options/faultPowerOptions";
import * as echarts from "echarts";
import setEchartsData from '@/utils/setEchartsData.js'

export default {
    props:{
        changeflag: { 
            type: String,
            default:''
        },
    },
    watch:{
        changeflag (newValue,oldValue) {
            if(!newValue) return
            this.powerStationName = localStorage.getItem("powerStationName")
            this.getData()
        }
    },
  mounted() {
    this.getData();
  },
  data() {
    return {
        date: '',
        powerStationName: localStorage.getItem("powerStationName"),
    };
  },
  methods: {
    init(eData) {
        this.$refs.faultPowerChart = echarts.init(document.getElementById("faultPowerChart"));
        let option = setOption.faultPowerOptions(eData);
        this.$refs.faultPowerChart.setOption(option);
    },
    getData(day){
        const that = this
        const _date = day?new Date(day):new Date()
        let params = {
            powerStationCode: localStorage.getItem("powerStationCode"),
            dateFormat: '%Y-%m-%d',
            summaryDate: setEchartsData.formatDate(_date),
        };
        this.$http.post("powerStationDeviceSummary/summaryByDays", params)
        .then((res) =>{
            this.init(res.data.data)
        })
        .catch((err)=>{})
    },
    gettwo(){
        const that = this
        let params = {
            powerStationCode: localStorage.getItem("powerStationCode"),
            dateFormat: '%Y-%m-%d',
            summaryDate: setEchartsData.formatDate(new Date()),
        };
        function getUserAccount() {
            return that.$http.post('powerStationDeviceSummary/summaryByDays',params);
        }
        
        function getUserPermissions() {
            return that.$http.post('powerStationDeviceSummary/summary',params);
        }
        that.$http.all([getUserAccount(), getUserPermissions()])
        .then(that.$http.spread(function (acct, perms) {
            // 两个请求现在都执行完成
            console.log('acct, perms', acct, perms)
        }));
    },
  }
}
</script>

<style lang="scss" scoped>
.head-class-left{
    margin-top: 10px;
    width: 100%;
    height: 349px;
    border-radius: 5px;
    background-color: #2d5981;
    box-sizing: border-box;
    padding: 15px;
    .center-item-head{
        display: flex;
        justify-content: space-between;
            .item-head-class{
        display: flex;
        justify-content: space-between;
        height: 38px;
        width: 100%;
        border-bottom: 1px solid #009fde;
        position: relative;
        .title-class{
            width: 150px;
            height: 100%;
            line-height: 40px;
            padding-left: 5px; 
            font-family: "Arial", sans-serif;
            color: #ffffff;
            font-size: 15px;
            background: #00b2e6;
        }
        .background-img{
            height: 100%;
            position: absolute;
            left: 119px;
            top: 0px;
        }
    }
        .form-item-class{
            display: flex;
            align-items: center;
            box-sizing: border-box;
            font-family: "Arial", sans-serif;
            color: #97b3cb;
            font-size: 13px;
            margin: 10px 0;
        }
    }
}
.electrcity-chart-class{
    width: 100%;
    height: 260px;
    box-sizing: border-box;
    .fourth-left{
        height: 100%;
        width: 100%;
    }
}
/deep/ .el-input__inner{
    width: 100%;
}
</style>