let datas = [
  { value: 10, name: '正常' },
  { value: 30, name: '故障' },
  { value: 20, name: '断链' },
]
export default {
  faultEchartsOptions (edata) {
    let _datas = [
      { value: edata.proportion, name: '已处理' },//edata.proportion
      { value: 100-edata.proportion, name: '未处理' },
    ]
    return {
      tooltip: {
        trigger: 'item', // 标题
        formatter: '{b}: {d}%' // 数据的显示方式
      },
      title: {
        text: edata.proportion+'%',
        subtext: '故障处置率',
        textStyle: {
          fontSize: 15,
          color: "#2acfa9",
          fontWeight: 600
        },
        subtextStyle: {
          fontSize: 13,
          color: "#f2f2f2",
          fontWeight: 400
        },
        textAlign: "center", //图例文字居中显示
        x: "46%",   //距离左边的距离
        y: "35%"    //距离上边的距离
      },
      // color: ["#10596d", "#Ff6666", "#999999"],
      color: ["#2acfa9","#10596d"],
      series: [
        {
          type: 'pie',
          radius: ['75%', '85%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
            }
          },
          data: _datas//datas
        }
      ]
    }
  }
}