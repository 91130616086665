import { render, staticRenderFns } from "./homeBottom.vue?vue&type=template&id=7f9949a0&scoped=true"
import script from "./homeBottom.vue?vue&type=script&lang=js"
export * from "./homeBottom.vue?vue&type=script&lang=js"
import style0 from "./homeBottom.vue?vue&type=style&index=0&id=7f9949a0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f9949a0",
  null
  
)

component.options.__file = "homeBottom.vue"
export default component.exports