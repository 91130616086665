<template>
    <div class="home-class">

        <div class="head-form-class">
            <!-- <el-input v-model="form.name" placeholder="请输入内容"></el-input> -->
            <!-- <div class="form-item-class">
                <div style="width: 40px">年月</div>
                <el-date-picker
                v-model="form.date"
                type="month"
                placeholder="选择年月">
                </el-date-picker></div> -->
            </div>
        <div class="head-class">
            <home-head-left :changeflag="changeflag"/>
            <home-head-center :changeflag="changeflag"/>
            <home-head-right @setpcode="setpcode"/>
        </div>
        <home-center :changeflag="changeflag"/>
        <home-bottom :changeflag="changeflag"/>
    </div>
</template>

<script>
import HomeHeadCenter from './components/homeHeadCenter.vue';
import HomeHeadLeft from "./components/homeHeadLeft.vue";
import HomeHeadRight from './components/homeHeadRight.vue';
import HomeCenter from './components/homeCenter.vue'
import HomeBottom from './components/homeBottom.vue';

export default {
    components:{
        HomeHeadLeft,
        HomeHeadCenter,
        HomeHeadRight,
        HomeCenter,
        HomeBottom
    },
    created(){
        this.getPowerStation()
    },
    mounted() {
    },
    data() {
        return {
            form: {
                name: '',
                data: ''
            },
            changeflag: null,
        };
    },
    methods: {
        async getPowerStation() {
            const queryInfo = {
                condition:{
                    userCode:JSON.parse(localStorage.getItem("userInfo")).userCode,
                },
                pageSize: 1000000
            }
            const res = await this.$http.post("/powerStation/powerStationList", queryInfo);
            if (res.data.code === 200) {
                localStorage.setItem("powerStationName",res.data.data.data[0].powerStationName)
                localStorage.setItem("powerStationCode",res.data.data.data[0].powerStationCode)
                this.changeflag = res.data.data.data[0].powerStationCode
            } else {
                this.$message.error(res.data);
            }
        },
        setpcode(val){
            this.changeflag = val || nill
        },
    }
}
</script>

<style lang="scss" scoped>
.home-class{
    background: #223f6c;
    padding: 10px;
    overflow-y: auto;
    height: 100%;
    .head-form-class{
        display: flex;
        justify-content: space-between;
        .form-item-class{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            box-sizing: border-box;
            font-family: "Arial", sans-serif;
            color: #97b3cb;
            font-size: 13px;
            margin-bottom: 5px;
        }
    }
    .head-class{
        display: flex;
        justify-content: space-between;
    }
}
/deep/ .el-form-item__label{
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #ffffff;
        text-align: left;
        line-height: normal;
        font-size: 18px;
}
/deep/ .el-input__inner{
    width: 200px;
  border-radius: 5px;
  border: 1px solid rgba(151, 179, 203, 0.55);
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #f2f2f2;
  text-align: left;
  line-height: normal;
}
</style>
