<template>
    <div class="head-class-left">
        <div class="item-head-class">
            <div class="title-class">发电量统计</div>
            <img src="../../../assets/images/yunwei/home-background.svg" class="background-img" alt="">
        </div>
        <div class="electrcity-chart-class">
            <div ref="electrcityStatisticsChart" id="electrcityStatisticsChart" class="fourth-left"></div>
        </div>
    </div>
</template>

<script>
import electrcityStatisticsOptions from "../options/electrcityStatisticsOptions";
import * as echarts from "echarts";
import setEchartsData from '@/utils/setEchartsData.js'

export default {
    props:{
        changeflag: { 
            type: String,
            default:''
        },
    },
    watch:{
        changeflag (newValue,oldValue) {
            if(!newValue) return
            this.powerStationName = localStorage.getItem("powerStationName")
            this.getData()
        }
    },
    mounted() {
        this.getData();
    },
    data() {
        return {
            powerStationName: localStorage.getItem("powerStationName"),
        };
    },
    methods: {
    init(edata) {
        this.$refs.electrcityStatisticsChart = echarts.init(document.getElementById("electrcityStatisticsChart"));
        let option = electrcityStatisticsOptions.setElectrcityStatisticsOptions(edata);
        this.$refs.electrcityStatisticsChart.setOption(option);
    },
    getData(){
        const that = this
        const week = setEchartsData.getweek()
        let params = {
            powerStationCode: localStorage.getItem("powerStationCode"),
            dateFormat: '%Y-%m-%d',
            summaryBeDate: week[0],
            summaryEddDate: week[1],
        };
        this.$http.post("powerStationDeviceSummary/summary", params)
        .then((res) =>{
            this.init(res.data.data)
        })
        .catch((err)=>{})
    },
  }
}
</script>

<style lang="scss" scoped>
.head-class-left{
    width: 36%;
    flex-grow: 1;
    margin: 0 10px;
    height: 300px;
    border-radius: 5px;
    background-color: #2d5981;
    box-sizing: border-box;
    padding: 9px;
        .item-head-class{
        display: flex;
        height: 38px;
        width: 100%;
        border-bottom: 1px solid #009fde;
        position: relative;
        .title-class{
            width: 100px;
            height: 100%;
            line-height: 40px;
            padding-left: 5px; 
            font-family: "Arial", sans-serif;
            color: #ffffff;
            font-size: 15px;
            background: #00b2e6;
        }
        .background-img{
            height: 100%;
            position: absolute;
            left: 67px;
        }
    }
}
.electrcity-chart-class{
    width: 100%;
    height: calc(100% - 38px);
    box-sizing: border-box;
    .fourth-left{
        height: 100%;
        width: 100%;
    }
}
</style>