<template>
    <div class="head-class-left">
        <div class="item-head-class">
            <div class="title-class">故障统计</div>
            <img src="../../../assets/images/yunwei/home-background.svg" class="background-img" alt="">
        </div>
        <div class="left-content">
            <div>
                <div class="lh-item">
                    <div class="lh-number-class">{{alarmData.dealTotal}}个</div>
                    <div class="lh-text-class">本月已处理故障</div>
                </div>
                <div class="lh-item">
                    <div class="lh-number-class">{{alarmData.alarmTotal}}个</div>
                    <div class="lh-text-class">本月故障总数</div>
                </div>
            </div>
            <div class="fault-chart-class">
                <div ref="faultChart" id="faultChart" class="fourth-left"></div>
            </div>
        </div>
    </div>
</template>

<script>
import setOption from "../options/faultEchartsOptions";
import * as echarts from "echarts";
import setEchartsData from '@/utils/setEchartsData.js'

export default {
    props:{
        changeflag: { 
            type: String,
            default:''
        },
    },
    watch:{
        changeflag (newValue,oldValue) {
            if(!newValue) return
            this.powerStationName = localStorage.getItem("powerStationName")
            this.getData()
        }
    },
  mounted() {
    this.getData();
  },
  data() {
    return {
        powerStationName: localStorage.getItem("powerStationName"),
        alarmData:{
            alarmTotal:0,
            dealTotal:0,
        },
    };
  },
  methods: {
    init(edata) {
        this.$refs.faultChart = echarts.init(document.getElementById("faultChart"));
        let option = setOption.faultEchartsOptions(edata);
        this.$refs.faultChart.setOption(option);
    },
    getData(){
        const that = this
        let params = {
            powerStationCode: localStorage.getItem("powerStationCode"),
            // dateFormat: '%Y-%m-%d',
            summaryDate: setEchartsData.formatDateMonth(new Date()),
        };
        this.$http.post("deviceAlarmRecord/selectAlarmMonthByStation", params)
        .then((res) =>{
            this.alarmData = res.data.data
            this.init(res.data.data)
        })
        .catch((err)=>{})
    },
  }
}
</script>

<style lang="scss" scoped>
.head-class-left{
    width: 30%;
    height: 300px;
    border-radius: 5px;
    background-color: #2d5981;
    box-sizing: border-box;
    padding: 10px;
    .item-head-class{
        display: flex;
        height: 38px;
        width: 100%;
        border-bottom: 1px solid #009fde;
        position: relative;
        .title-class{
            width: 90px;
            height: 100%;
            line-height: 40px;
            padding-left: 5px; 
            font-family: "Arial", sans-serif;
            color: #ffffff;
            font-size: 15px;
            background: #00b2e6;
        }
        .background-img{
            height: 100%;
            position: absolute;
            left: 59px;
        }
    }
    .left-content{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: calc(100% - 38px);
        padding: 25px;
        .lh-item{
            // width: 50%;
            padding: 18px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .lh-number-class{
                box-sizing: border-box;
                font-family: "Arial", sans-serif;
                color: #33e9bd;
                font-size: 16px;
            }
            .lh-text-class{
                box-sizing: border-box;
                font-family: "Arial", sans-serif;
                color: #f2f2f2;
                font-size: 13px;
            }
        }
    }
}
.fault-chart-class{
    width: 50%;
    height: 160px;
    min-height: 160px;
    box-sizing: border-box;
    .fourth-left{
        height: 100%;
        width: 100%;
    }
}
</style>