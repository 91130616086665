<template>
    <div class="head-class-right">
        <div class="flex-class">
             <div class="item-head-class">
                <div class="title-class">电站信息</div>
                <img src="../../../assets/images/yunwei/home-background.svg" class="background-img" alt="">
            </div>
            <!-- <el-input v-model="powerStationName" placeholder="电站名称" @blur="getListData"></el-input> -->
            <el-select style="width: 300px;" v-model="powerStationName" placeholder="电站名称" @change="getprincipalCode">
                <el-option
                    v-for="item in powerStationList"
                    :key="item.id"
                    :label="item.powerStationName"
                    :value="item.powerStationName"
                >
                </el-option>
            </el-select>
        </div>
        <div class="right-content">
            <div class="right-content-item" v-for="(i,index) in itemList" :key="index">
                <div class="image-class">
                    <img src="../../../assets/images/yunwei/electrcity.png" alt="">
                </div>
                <div class="text-class">{{i.text}}</div>
                <div class="number-class">{{i.value}}</div>
                <div class="text-class">{{i.unit}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created(){
        this.getPowerStation();
    },
  mounted() {
  },
  data() {
    return {
        powerStationList: [],
        powerStationName: '',
        powerStationCode:'',
        itemList: [
            // {index: 0, text: '装机容量', value: 0, unit: 'MW'},
            {index: 1, text: '日发电量', value: 0, unit: 'kWh'},
            {index: 2, text: '月发电量', value: 0, unit: '万kWh'},
            {index: 3, text: '年发电量', value: 0, unit: '万kWh'},
            {index: 4, text: '累计节约标准煤', value: 0, unit: 't'},
            {index: 5, text: '累计减排CO2', value: 0, unit: 't'},

        ]
    };
  },
  methods: {
    async getPowerStation() {
        const queryInfo = {
            condition:{
                userCode:JSON.parse(localStorage.getItem("userInfo")).userCode,
            },
            pageSize: 1000000
        }
      const res = await this.$http.post("/powerStation/powerStationList", queryInfo);
      if (res.data.code === 200) {
        this.powerStationList = res.data.data.data;
        localStorage.setItem("powerStationName",res.data.data.data[0].powerStationName)
        localStorage.setItem("powerStationCode",res.data.data.data[0].powerStationCode)
        this.powerStationCode = res.data.data.data[0].powerStationCode
        this.powerStationName = res.data.data.data[0].powerStationName
        this.getListData()
      } else {
        this.$message.error(res.data);
      }
    },
    getprincipalCode(val){
        let index = this.powerStationList.findIndex(item=>item.powerStationName == val)
        this.powerStationCode = this.powerStationList[index].powerStationCode
        localStorage.setItem("powerStationName",val)
        localStorage.setItem("powerStationCode",this.powerStationCode)
        this.$emit('setpcode',this.powerStationCode)
        this.getListData()
    },
    getListData(){
        this.itemList.map(item=>{
            return item.value = null
        })
        this.$http.post('powerStationDevice/queryByPowerStation',{powerStationCode: this.powerStationCode}).then(res=>{
            if(res.data.code==200){
                // this.itemList[0].value = res.data.data.currEfficiency
                this.itemList[0].value = res.data.data.currDayPowerNum
                this.itemList[1].value = res.data.data.currMonthPowerNum
                this.itemList[2].value = res.data.data.currYearPowerNum
                const coal = (res.data.data.totalPowerNum * 0.4 / 1000.).toFixed(2)
                const co2 = (res.data.data.totalPowerNum * 0.997 / 1000).toFixed(2)
                this.itemList[3].value = coal
                this.itemList[4].value = co2
            }else{
                this.$message.error(res.data.message)
            }
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.head-class-right{
    width: 30%;
    height: 300px;
    border-radius: 5px;
    background-color: #2d5981;
    box-sizing: border-box;
    padding: 9px;
    .flex-class{
        display: flex;
        justify-content: space-between;
            border-bottom: 1px solid #009fde;
        .item-head-class{
            display: flex;
            justify-content: space-between;
            height: 38px;
            width: 100%;
            position: relative;
            .title-class{
                width: 90px;
                height: 100%;
                line-height: 40px;
                padding-left: 5px; 
                font-family: "Arial", sans-serif;
                color: #ffffff;
                font-size: 15px;
                background: #00b2e6;
            }
            .background-img{
                height: 100%;
                position: absolute;
                left: 57px;
            }
        }
    }
    .right-content{
        height: calc(100% - 38px);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .right-content-item{
            display: flex;
            align-items: center;
            .image-class{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 22px;
                height: 22px;
                box-sizing: border-box;
                background: url('../../../assets/images/yunwei/electrcityBackground.png') no-repeat center center;
                background-size: 100% 100%;
                margin: 10px;
                img{
                    width: 10px;
                    height: 10px;
                    box-sizing: border-box;
                }
            }
            .text-class{
                box-sizing: border-box;
                font-family: "Arial", sans-serif;
                color: #f2f2f2;
                font-size: 11px;
            }
            .number-class{
                box-sizing: border-box;
                font-family: "Arial", sans-serif;
                color: #69dda4;
                font-size: 15px;
                margin: 0 2px;
            }
        }
    }
}
</style>