export default {
  /**
   * 格式化日期：yyyy-MM-dd
   * @param  {Date} date ''
   */
  formatDate(date) {
    var myyear = date.getFullYear();
    var mymonth = date.getMonth() + 1;
    var myweekday = date.getDate();

    if (mymonth < 10) {
        mymonth = "0" + mymonth;
    }
    if (myweekday < 10) {
        myweekday = "0" + myweekday;
    }
    return (myyear + "-" + mymonth + "-" + myweekday);
  },
  formatDateMonth(date) {
    var myyear = date.getFullYear();
    var mymonth = date.getMonth() + 1;

    if (mymonth < 10) {
        mymonth = "0" + mymonth;
    }
    return (myyear + "-" + mymonth);
  },
  /**
   * 获取周一、周末
   */
  getweek(){
    var now = new Date();
    var nowTime = now.getTime() ;
    var day = now.getDay()|| 7;// 不加 || 7.周末会变成下周一
    var oneDayTime = 24*60*60*1000 ;
    var MondayTime = nowTime - (day-1)*oneDayTime ;//显示周一
    var SundayTime =  nowTime + (7-day)*oneDayTime ;//显示周日
    const week = [this.formatDate(new Date(MondayTime)),this.formatDate(new Date(SundayTime))]
    return week
},
}