<template>
    <div class="head-class-left">
        <div class="bottom-item-head">
            <div class="item-head-class">
                <div>
                    <div class="title-class">发电单元分析</div>
                    <img src="../../../assets/images/yunwei/home-background.svg" class="background-img" alt="">
                </div>
                <!-- <div class="form-item-class">
                    <div style="width: 40px">类型</div>
                    <el-select v-model="value" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div> -->
            </div>
        </div>
        <div class="bottom-content">
            <!-- <div class="electrcity-chart-class">
                <div ref="generationAnalysisChart" id="generationAnalysisChart" class="fourth-left"></div>
            </div> -->
            <div class="table-class">
                <el-table
                    :data="tableData"
                    v-loading="loading"
                    max-height='400'
                    style="width: 100%"
                >
                    <el-table-column label="序号" type="index" align="center">
                    </el-table-column>
                    <el-table-column
                    label="逆变器"
                    prop="deviceTypeName"
                    sortable="custom"
                    align="center"
                    >
                        <template slot-scope="scope">
                            <span>{{scope.row.deviceName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="容量(kW)"
                    prop="volume"
                    sortable="custom"
                    align="center"
                    >
                        <template slot-scope="scope">
                            <span>{{scope.row.volume}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                    label="输出电量(kWh)"
                    align="center"
                    >
                        <template slot-scope="scope">
                            <span>{{scope.row.currDayPowerNum}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currPage"
                    :page-sizes="[2, 5, 10, 15]"
                    :page-size="pageSize"
                    class="pagination-style"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                ></el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
import {generationAnalysisOptions} from "../options/generationAnalysisOptions";
import * as echarts from "echarts";
// import setEchartsData from '@/utils/setEchartsData.js'

export default {
    props:{
        changeflag: { 
            type: String,
            default:''
        },
    },
    watch:{
        changeflag (newValue,oldValue) {
            if(!newValue) return
            this.powerStationName = localStorage.getItem("powerStationName")
            this.getData()
        }
    },
  mounted() {
    this.getData();
  },
  data() {
    return {
        value: '', // 类型
        powerStationName: localStorage.getItem("powerStationName"),
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        currPage: 1,
        pageSize: 5,
        total: 0,
        loading:false,
        tableData: [
            {
                index: 1
            },
            {
                index: 2
            },
            {
                index: 3
            }
        ]
    };
  },
  methods: {
    init() {
        this.$nextTick(() => {
            // this.initECharts()
        })
    },
    initECharts(){
        this.$refs.generationAnalysisChart = echarts.init(document.getElementById("generationAnalysisChart"));
        let option = generationAnalysisOptions;
        this.$refs.generationAnalysisChart.setOption(option);
    },
    getData(){
        const that = this
        this.loading = true;
        let params = {
            condition: {
                powerStationCode: localStorage.getItem("powerStationCode"),
            },
            "currPage": this.currPage,
            "pageSize": this.pageSize,
        };
        this.$http.post("powerStationDevice/list", params)
        .then((res) =>{
            this.total = res.data.data.count;
            this.tableData = res.data.data.data
            this.loading = false;
            this.init()
        })
        .catch((err)=>{})
    },
    handleSizeChange(val){
        this.pageSize = val;
        this.getData();
    },
    handleCurrentChange(val){
        this.currPage = val;
        this.getData();
    }
  }
}
</script>

<style lang="scss" scoped>
.head-class-left{
    margin-top: 10px;
    width: 100%;
    // height: 349px;
    border-radius: 5px;
    background-color: #2d5981;
    box-sizing: border-box;
    padding: 15px;
    .bottom-item-head{
        display: flex;
        justify-content: space-between;
        .item-head-class{
            display: flex;
            justify-content: space-between;
            height: 38px;
            width: 100%;
            border-bottom: 1px solid #009fde;
            position: relative;
            .title-class{
                width: 120px;
                height: 100%;
                line-height: 40px;
                padding-left: 5px;
                font-family: "Arial", sans-serif;
                color: #ffffff;
                font-size: 15px;
                background: #00b2e6;
            }
            .background-img{
                height: 100%;
                position: absolute;
                left: 89px;
                top: 0px;
            }
        }
        .form-item-class{
            display: flex;
            align-items: center;
            box-sizing: border-box;
            font-family: "Arial", sans-serif;
            color: #97b3cb;
            font-size: 13px;
            margin: 10px 0;
        }
    }
    .bottom-content{
        display: flex;
        align-items: center;
        justify-content: center;
        .electrcity-chart-class{
            width: 20%;
            height: 170px;
            box-sizing: border-box;
            .fourth-left{
                height: 100%;
                width: 100%;
            }
        }
        .table-class{
            margin-left: 10px;
            margin-top: 15px;
            padding-bottom: 10px;
            width: 90%;
            border: 1px solid rgba(151, 179, 203, 0.55);
            overflow-y: auto;
        }
    }
}

/deep/ .el-form{
    display: flex;
}
/deep/ .el-form-item{
    display: flex;
    align-items: center;

}
/deep/ .el-form-item__label{
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #ffffff;
        font-size: 18px;
}
    /deep/ .el-input__inner{
        width: 100% !important;
    }
/deep/ .el-table{
    margin-top: 0px;
}
/deep/ .el-table--border, .el-table--group {
    border: 1px solid rgba(151, 179, 203, 0.55);
}
    /deep/ .el-table--border th{
        background-color: #21527e;

     border: 1px solid #737c85;
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }

    /deep/ .el-table th{
        background-color: #2d5981;
     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }
    /deep/ .el-table td {
        background-color: #2d5981;
     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }
  /deep/ .el-table__body-wrapper{
    background: #2d5981;
  }
  /deep/ .el-pagination button:disabled{
    background: rgba(255, 255, 255, 0);
  }
  /deep/ .el-pager li.active {
    color: #f2f2f2;
    background-color: #00b2e6;
    cursor: default;
}

  /deep/ .el-pagination__total{
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
  /deep/.el-pager,/deep/.btn-prev,/deep/.btn-next{
    background:rgba(255, 255, 255, 0);
    color: #fff;
    .number{
        background:rgba(255, 255, 255, 0);
    }
  }
 /deep/ .el-pagination__jump{
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
  }
  ::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color: #223f6c !important;
}
</style>
