export const generationAnalysisOptions = {
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}台 ({d}%)'
  },
  grig: {
    left: "10%"
  },
  legend: {
    textStyle: { //图例文字的样式
      color: '#97b3cb',
      fontSize: 12
    },
    orient: 'vertical',
    bottom: "25%",
    left: "0%",
    icon: 'circle',
    data: ['良', '优', '中', '差']
  },
  color: ["#00c97b", "#Ff6666", '#f59a23', "#999999"],
  series: [
    {
      label: {
        width: 40,
        color: "#999",
        formatter: " {c}台 ",
        show: false
      },
      type: 'pie',
      radius: '65%',
      center: ['56%', '50%'],
      selectedMode: 'single',
      data: [
        { value: 50, name: '良' },
        { value: 25, name: '优' },
        { value: 25, name: '中' },
        { value: 0, name: '差' }
      ],
    }
  ]

}