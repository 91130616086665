// 故障功率辐射曲线
export default {
  faultPowerOptions (edata) {
    const timelist = edata.map(item=>{
      return item.date.substring(11,16)
    })
    const efficiencyData = edata.map(item=>{
      return item.efficiency
    })
    return {
      grid: {
        left: "6%",
        right: "6%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: timelist,
          // [
          //   "6:00",
          //   "6:30",
          //   "7:00",
          //   "7:30",
          //   "8:00",
          //   "8:30",
          //   "9:00",
          //   "9:30",
          //   "10:00",
          //   "10:30",
          //   "11:00",
          // ],
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#97b3cb", // x轴颜色
            },
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
              width: 1,
            },
          },
        },
        {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitArea: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          data: [
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
            "星期日",
          ],
        },
      ],
      yAxis: [
        {
          name: "功率(kW)",
          nameTextStyle: {
            fontSize: 12,
            color: "rgba(255, 255, 255, 1)",
            align: "right",
          },
          type: "value",
          axisLabel: {
            formatter: "{value}",
            show: true,
            textStyle: {
              color: "#97b3cb", // x轴颜色
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed", // 线型为虚线
              color: "#97b3cb",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#fff",
              width: 1,
            },
          },
        },
        {
          name: "辐射强度(W/m²)",
          nameTextStyle: {
            fontSize: 12,
            color: "rgba(255, 255, 255, 1)",
            align: "right",
          },
          type: "value",
          axisLabel: {
            show: true,
            formatter: "{value}",
            textStyle: {
              color: "#97b3cb", // x轴颜色
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed", // 线型为虚线
              color: "#97b3cb",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
              width: 1,
            },
          },
        },
      ],
      series: [
        {
          name: "功率(kW)",
          type: "bar",
          barWidth: 12,
          data: efficiencyData,//[370, 700, 200, 400, 300, 300, 123, 233, 123],
          itemStyle: {
            color: "#45f9d6",
          },
          label: {
            show: false, // 开启标签显示
            position: "top", // 标签的位置，可以根据需要调整
            textStyle: {
              color: "#45f9d6",
            },
          },
        },
        {
          type: "line",
          name: "辐射强度(W/m²)",
          // data: ["93", "50", "33", "99", "62", "6", "45"],
          zlevel: 3,
          color: "rgba(250, 204, 20, 1)",
        }
      ],
    };
  }
}
  